import { SubmissionWithAggregates } from '@/types/submission';
import { z } from 'zod';

export const OPERATION_TYPES = {
    UNFINISHED: 'unfinished',
    HOT: 'hot',
    WON: 'won',
    LOST: 'lost',
    RETURNED: 'returned',
    ALL: 'all',
};

export type OPERATION_TYPES = (typeof OPERATION_TYPES)[keyof typeof OPERATION_TYPES];

export const DealSituations = {
    OPEN: 'open',
    HOT: 'hot',
    WON: 'won',
    LOST: 'lost',
};

export const OperationsFormFilters = z.object({
    mainStatuses: z.array(z.string()).optional(),
    subStatus: z.string().optional(),
    bankStatus: z.string().optional(),
    contractStatus: z.string().optional(),
    createdAt: z.string().datetime().optional(),
    wonBetween: z.array(z.date()).optional(),
    dealFirstWonTimeFrom: z.string().datetime().optional(),
    dealFirstWonTimeTo: z.string().datetime().optional(),
    externalId: z.string().optional(),
    financier: z.string().optional(),
    licensePlate: z.string().optional(),
    dealSituations: z
        .array(
            z.union([
                z.literal(DealSituations.HOT),
                z.literal(DealSituations.WON),
                z.literal(DealSituations.LOST),
                z.literal(DealSituations.OPEN),
            ]),
        )
        .optional(),
    dealStages: z.array(z.string()).optional(),
    salesAgent: z.object({ label: z.string(), value: z.string() }).optional(),
    salesAgentUuid: z.string().optional(),
    status: z.string().optional(),
    financeAgentUuid: z.string().optional(),
    isAmountReceivedFromBank: z.string().optional(),
    isAmountTransferredToClient: z.string().optional(),
    financeAgent: z
        .object({
            label: z.string(),
            value: z.string(),
        })
        .nullable()
        .optional(),
});

export const OperationFilters = OperationsFormFilters.extend({
    max: z.number().optional(),
    offset: z.number().optional(),
    page: z.number().optional(),
    dealTier: z.string().optional(),
});

export const operationTypeButtonFilters = {
    [OPERATION_TYPES.UNFINISHED]: {
        dealSituations: ['OPEN'],
        dealStages: ['LEAD_CREATED', 'LEAD_CALLED', 'LEAD_PRE_QUOTED', 'LEAD_OPPORTUNITY', 'LEAD_QUOTED'],
    },
    [OPERATION_TYPES.HOT]: {
        dealSituations: ['OPEN'],
        dealStages: ['DEAL_CREATED', 'DEAL_QUALIFIED'],
        mainStatuses: ['PENDING', 'PROCESSING', 'WAITING', 'IN_ANALYSIS', 'PRE_APPROVED', 'ACCEPTED'],
    },
    [OPERATION_TYPES.WON]: {
        mainStatuses: ['COLLECTED'],
        dealSituations: ['OPEN', 'LOST', 'WON'],
    },
    [OPERATION_TYPES.LOST]: {
        mainStatuses: ['REJECTED', 'UNSUCCESSFULLY_APPROVED', 'CANCELLED'],
        dealSituations: ['OPEN', 'LOST', 'WON'],
    },
    [OPERATION_TYPES.RETURNED]: {
        mainStatuses: ['RETURN_IN_PROCESS', 'RETURN', 'EARLY_TERMINATION'],
        dealSituations: ['OPEN', 'LOST', 'WON'],
    },
    [OPERATION_TYPES.ALL]: {
        dealSituations: ['OPEN', 'LOST', 'WON'],
    },
};

export const OperationListView = z.object({
    items: z.array(SubmissionWithAggregates).default([]),
    pages: z.number(),
    offset: z.number(),
    totalItems: z.number(),
});

export const GetOperationsListResponse = z.object({
    max: z.number(),
    offset: z.number(),
    items: z.array(SubmissionWithAggregates).default([]),
    totalItems: z.number(),
});

export type OperationsFormFilters = z.infer<typeof OperationsFormFilters>;
export type OperationFilters = z.infer<typeof OperationFilters>;
export type GetOperationsListResponse = z.infer<typeof GetOperationsListResponse>;
export type OperationListView = z.infer<typeof OperationListView>;
