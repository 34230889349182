import { z } from 'zod';

export const LoanPurpose = z.ZodEnum.create([
    'HOME_RENOVATION',
    'NEW_VEHICLE',
    'USED_VEHICLE',
    'HOME_FURNISHING_APPLIANCES',
    'TRAINING',
    'HEALTHCARE',
    'TRAVEL_OR_LEISURE',
    'EVENT_OR_CELEBRATION',
    'NEW_BUSINESS',
    'CASH_FLOW_NEEDS',
    'UNIFY_CREDITS',
    'OTHER',
]);
export type LoanPurpose = z.infer<typeof LoanPurpose>;

export const loanPurposeOptions: Record<LoanPurpose, string> = {
    HOME_RENOVATION: 'Reforma de vivienda',
    NEW_VEHICLE: 'Vehículo Nuevo',
    USED_VEHICLE: 'Vehículo Usado',
    HOME_FURNISHING_APPLIANCES: 'Muebles/Equipamiento del hogar',
    TRAINING: 'Estudios',
    HEALTHCARE: 'Sanidad',
    TRAVEL_OR_LEISURE: 'Viajes/Ocio',
    EVENT_OR_CELEBRATION: 'Acontecimiento familiar/Celebr',
    NEW_BUSINESS: 'Creación nueva empresa',
    CASH_FLOW_NEEDS: 'Necesidad de tesorería',
    UNIFY_CREDITS: 'Unificar créditos',
    OTHER: 'Otros',
};
