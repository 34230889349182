import { z } from 'zod';
import { PaginatedListFilters } from '@/types';

export const ChatMessageType = z.enum(['TEXT', 'ATTACHMENT']);
export type ChatMessageType = z.infer<typeof ChatMessageType>;

const MessageAttachmentType = z.enum(['IMAGE', 'VIDEO', 'AUDIO', 'DOCUMENT']);

export const ChatMessageTraffic = z.enum(['INCOMING', 'OUTGOING']);

export const ChatAttachment = z.object({
    id: z.number(),
    uuid: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    messageId: z.number(),
    type: MessageAttachmentType,
    url: z.string().url(),
    fileName: z.string(),
    ext: z.string(),
    size: z.number(),
    mime: z.string(),
});
export const ChatMessage = z.object({
    id: z.number(),
    uuid: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    contactId: z.number(),
    timestamp: z.string().datetime(),
    traffic: ChatMessageTraffic,
    type: ChatMessageType,
    text: z.string().nullable(),
    attachments: z.array(ChatAttachment),
});

export const ChatListFormFilters = z.object({
    phone: z.string(),
    type: ChatMessageType.optional(),
    extId: z.string().optional(),
});

export type ChatAttachment = z.infer<typeof ChatAttachment>;
export type ChatMessage = z.infer<typeof ChatMessage>;
export type ChatListFormFilters = z.infer<typeof ChatListFormFilters>;
export type ChatListFilters = PaginatedListFilters & ChatListFormFilters;
