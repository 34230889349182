import { z } from 'zod';

export const JobPosition = z.ZodEnum.create([
    'EXECUTIVE',
    'QUALIFIED_TECHNICIAN',
    'ADMINISTRATIVE',
    'MANUAL_WORKER',
    'REPRESENTATIVE',
    'SPECIALIST_WORKER',
    'LIBERAL_PROFESSION',
    'PEDDLER',
    'OTHER',
]);
export type JobPosition = z.infer<typeof JobPosition>;

export const jobPositionsOptions: Record<JobPosition, string> = {
    EXECUTIVE: 'Directivo',
    QUALIFIED_TECHNICIAN: 'Técnico titulado',
    ADMINISTRATIVE: 'Administrativo',
    MANUAL_WORKER: 'Trabajador Manual',
    REPRESENTATIVE: 'Representante',
    SPECIALIST_WORKER: 'Trabajador especialista',
    LIBERAL_PROFESSION: 'Profesión liberal',
    PEDDLER: 'Vendedor ambulante',
    OTHER: 'Otro',
};
