import { z } from 'zod';
import { Stage, Tier } from './deals';
import { CatalogueVehicleSubStatuses } from './Vehicle';

export const dealStagesStatistics = z.object({
    stage: Stage,
    count: z.number(),
});

export const catalogueVehicleSubstatusStatistics = z.object({
    subStatus: CatalogueVehicleSubStatuses,
    count: z.number(),
});

export const Statistics = z.object({
    deals: z.object({
        stages: z.array(dealStagesStatistics),
        total: z.number(),
    }),
    catalogueVehicles: z.object({
        subStatuses: z.array(catalogueVehicleSubstatusStatistics),
        total: z.number(),
    }),
});

export const GetStatisticsResponse = Statistics;

export const StatisticsFilters = z.object({
    salesAgent: z.string(),
    processorAgent: z.string(),
    tier: Tier,
});

export type DealStagesStatistics = z.infer<typeof dealStagesStatistics>;
export type CatalogueVehicleSubstatusStatistics = z.infer<typeof catalogueVehicleSubstatusStatistics>;
export type StatisticsFilters = z.infer<typeof StatisticsFilters>;
export type Statistics = z.infer<typeof Statistics>;
export type GetStatisticsResponse = z.infer<typeof GetStatisticsResponse>;
