import { z } from 'zod';

export const JobType = z.ZodEnum.create([
    'INDUSTRY',
    'COMMERCE',
    'SERVICES',
    'HOSPITALITY',
    'CONSTRUCTION',
    'TRANSPORTATION',
    'AGRICULTURE_FARMING_FISHING',
    'PUBLIC_ADMINISTRATION',
    'OTHER_ACTIVITIES',
]);
export type JobType = z.infer<typeof JobType>;

export const jobTypeOptions: Record<JobType, string> = {
    INDUSTRY: 'Industria',
    COMMERCE: 'Comercio',
    SERVICES: 'Servicios',
    HOSPITALITY: 'Hostelería',
    CONSTRUCTION: 'Construcción',
    TRANSPORTATION: 'Transporte',
    AGRICULTURE_FARMING_FISHING: 'Agricultura, ganadería y pesca',
    PUBLIC_ADMINISTRATION: 'Administración pública',
    OTHER_ACTIVITIES: 'Otras actividades',
};
