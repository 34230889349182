import { z } from 'zod';

export function typedObjectKeys<T extends object>(object: T) {
    return Object.keys(object) as (keyof typeof object)[];
}

export type PartialRecord<K extends string, V> = {
    [key in K]?: V;
};

export type SelectOption<T> = {
    label: string;
    value: T;
};

export const Option = z.object({
    label: z.string().optional(),
    value: z.any(),
});

export type ApiError = {
    message: string;
};

export type MapItem<T> = {
    [key: string]: T;
} & {
    id: string;
};

export const GenericError = z.object({
    error: z.boolean().default(true),
    errorCode: z.string(),
    message: z.string(),
});

export type FormProps = {
    onSuccess: () => void;
    onError: () => void;
};

export const DocumentType = z.ZodEnum.create(['DNI', 'NIE', 'CIF']);
// TODO: Review IdentificationNumber type props, duplicated with different prop options in IdentificationNumber apps/connect/types/Client/details.ts
export const IdentificationNumber = z.object({
    number: z.string().optional(),
    type: DocumentType.nullable(),
});

export const Feature = z.object({
    uuid: z.string(),
    code: z.string().optional(),
    name: z.string().optional(),
    value: z.number().optional(),
    slug: z.string().optional(),
});

export const Measurement = z.object({
    amount: z.number(),
    unit: z.string().optional(),
    currency: z.string().optional(),
    precision: z.number().optional(),
});

export const Currency = z.enum(['EUR', 'USD']);

export const PaginatedListFilters = z.object({
    max: z.number().optional(),
    offset: z.number().optional(),
});

export const PaginatedList = z.object({
    max: z.number(),
    offset: z.number(),
    totalItems: z.number(),
    items: z.array(z.any()),
});

export type Paginated<T> = {
    max: number;
    offset: number;
    totalItems: number;
    items: T[];
};
export const PaginatedSchema = <T>(itemSchema: z.ZodType<T>) =>
    z.object({
        max: z.number(),
        offset: z.number(),
        totalItems: z.number(),
        items: z.array(itemSchema),
    });

export const BulkCsvReport = z.array(
    z.object({
        row: z.number(),
        field: z.string(),
        code: z.any(),
        value: z.string().optional(),
    }),
);

export type AnyObject = { [key: string]: any };

export type DocumentType = z.infer<typeof DocumentType>;
export type Measurement = z.infer<typeof Measurement>;
export type GenericError = z.infer<typeof GenericError>;
export type Option = z.infer<typeof Option>;
export type Feature = z.infer<typeof Feature>;
export type Currency = z.infer<typeof Currency>;
export type PaginatedListFilters = z.infer<typeof PaginatedListFilters>;
export type BulkCsvReport = z.infer<typeof BulkCsvReport>;
export type IdentificationNumber = z.infer<typeof IdentificationNumber>;
