import { DocumentType } from '@/types';
import {
    CompanyBillingType,
    Gender,
    HouseType,
    IncomeSource,
    JobPosition,
    JobType,
    LegalNature,
    MaritalStatus,
    ProfessionalStatus,
} from '@/types/Client/options';
import { BooleanChoice } from '@/types/options';
import { z } from 'zod';

// Not using the generic because the expected type from the patch is different
export const IdentificationNumber = z.object({
    number: z.string().nullable(),
    type: DocumentType.nullable(),
});
export type IdentificationNumber = z.infer<typeof IdentificationNumber>;

export const Client = z.object({
    uuid: z.string(),
    externalIds: z.record(z.string()).nullable(),
    legalNature: LegalNature.nullable(),
    identificationNumber: z.string().nullable(),
    email: z.string().nullable(),
    phone: z.string().nullable(),
    secondPhone: z.string().nullable(),
    name: z.string(),
    lastName: z.string(),
    secondLastName: z.string().nullable(),
    dateOfBirth: z
        .string()
        .nullable()
        .transform((val) => (val ? new Date(val) : null)),
    gender: Gender.nullable(),
    maritalStatus: MaritalStatus.nullable(),
    numberOfChildren: z.number().nullable(),
    nationality: z.string().nullable(),
    countryOfBirth: z.string().nullable(),
    legalName: z.string().nullable(),
    fiscalIdentificationNumber: z.string().nullable(),
});

export const BillingAddress = z.object({
    houseType: HouseType.nullable(),
    route: z.string().nullable(),
    streetNumber: z.string().nullable(),
    postalCode: z.string().nullable(),
    city: z.string().nullable(),
    province: z.string().nullable(),
    domicile: z.string().nullable(),
    complete: z.string().nullable(),
});

export const ForeignDetails = z.object({
    residenceStartDate: z
        .string()
        .datetime()
        .nullable()
        .transform((val) => (val ? new Date(val) : null)),

    residenceCardExpirationDate: z
        .string()
        .datetime()
        .nullable()
        .transform((val) => (val ? new Date(val) : null)),

    socialSecurityRegistrationDate: z
        .string()
        .datetime()
        .nullable()
        .transform((val) => (val ? new Date(val) : null)),
    numberOfJobs: z.number().nullable(),
});

export const Employment = z.object({
    jobPosition: JobPosition.nullable(),
    monthlyIncome: z.number().nullable(),
    annualIncome: z.number().nullable(),
    companyName: z.string().nullable(),
    professionalStatus: ProfessionalStatus.nullable(),
    seniorityDate: z
        .string()
        .nullable()
        .transform((val) => (val ? new Date(val) : null)),
    incomeSource: IncomeSource.nullable(),
    permanentContract: BooleanChoice.nullable(),
    jobType: JobType.nullable(),
    companyBillingType: CompanyBillingType.nullable(),
});

export const BankDetails = z.object({
    iban: z.string().nullable(),
    swift: z.string().nullable(),
    isAccountAtLeast6MonthsOlder: z.boolean().nullable(),
    isBalanceAtLeast200: z.boolean().nullable(),
});

export const CoOwner = z.object({
    exists: z.boolean(),
    name: z.string().nullable(),
    lastName: z.string().nullable(),
    secondLastName: z.string().nullable(),
    phone: z.string().nullable(),
    email: z.string().nullable(),
    identificationNumber: IdentificationNumber.nullable(),
});

export const ClientDetails = Client.extend({
    billingAddress: BillingAddress.nullable(),
    foreignDetails: ForeignDetails.nullable(),
    employment: Employment.nullable(),
    bankDetails: BankDetails.nullable(),
    coOwner: CoOwner.nullable(),
    rentOrMortgageAmount: z.number().nullable(),
    linkDocFolder: z.string().nullable(),
    createdAt: z.string().datetime(),
});

export type ClientDetails = z.infer<typeof ClientDetails>;
