import { z } from 'zod';

export const MaritalStatus = z.ZodEnum.create(['SINGLE', 'MARRIED', 'DIVORCED', 'WIDOWER']);
export type MaritalStatus = z.infer<typeof MaritalStatus>;

export const maritalStatusOptions: Record<MaritalStatus, string> = {
    SINGLE: 'Soltero',
    MARRIED: 'Casado',
    DIVORCED: 'Divorciado',
    WIDOWER: 'Viudo',
};
