import { Measurement } from '@/types/generics';
import { z } from 'zod';

export const productSources = {
    C2C_COCHESNET: 'Coches.net',
    C2C_AUTOCASION: 'Autocasion',
    C2C_AUTOSCOUT: 'Autoscout',
    C2C_MILANUNCIOS: 'Milanuncios',
    C2C_WALLAPOP: 'Wallapop',
    C2C_CLIDRIVECOM: 'Clidrive.com',
    // C2C_COCHESCOM: 'Coches.com',
};

export const originSources = {
    // WHATSAPP: 'Whatsapp',
    // WEB: 'Web',
    // PARTNER: 'Partner',
    // AFFILIATE: 'Affiliate',
    CALL: 'Llamada',
    EMAIL: 'Email',
    CLIENT_VISIT: 'Visita',
};

export const DealCreationFormData = z.object({
    licensePlate: z.string().nullable(),
    tier: z.string(),
    productSource: z.string(),
    originSource: z.string(),
    createdAt: z.string().datetime(),
    salesAgent: z.object({
        label: z.string(),
        value: z.string(),
    }),
    name: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().optional(),
    phone: z.string().optional(),
    content: z.string().optional(),
});

export const GetVehicleDetailsParams = z.object({
    licensePlate: z.string(),
});

export const GetVehicleDetailsResponse = z.object({
    licensePlate: z.string(),
    make: z
        .object({
            name: z.string(),
        })
        .nullable(),
    model: z
        .object({
            name: z.string(),
        })
        .nullable(),
    color: z
        .object({
            name: z.string(),
        })
        .nullable(),
    power: z.number().nullable(),
    yearRegistration: z.number().nullable(),
    vin: z.string().nullable(),
    sellingPrice: Measurement.nullable(),
    sellingCountedPrice: Measurement.nullable(),
    hasReservation: z.boolean(),
    reservationDealUuid: z.string().nullable(),
    kilometers: z.number().nullable(),
    cochesNetLink: z.string().nullable(),
    wallapopLink: z.string().nullable(),
    autocasionLink: z.string().nullable(),
    registrationDate: z.string().nullable(),
});

export const PostDealParams = z.object({
    licensePlate: z.string().nullable(),
    tier: z.string(),
    productSource: z.string(),
    originSource: z.string(),
    createdAt: z.string().datetime(),
    salesAgentUuid: z.string(),
    clientUuid: z.string().optional(),
    name: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().optional(),
    phone: z.string().optional(),
    note: z
        .object({
            title: z.string(),
            content: z.string(),
        })
        .optional(),
});

export const PostDealResponse = z.object({
    uuid: z.string(),
    vehicle: z
        .object({
            uuid: z.string(),
            licensePlate: z.string(),
        })
        .optional(),
    situation: z.string(),
    tier: z.string(),
    createdAt: z.string(),
});

export type DealCreationFormData = z.infer<typeof DealCreationFormData>;
export type GetVehicleDetailsParams = z.infer<typeof GetVehicleDetailsParams>;
export type GetVehicleDetailsResponse = z.infer<typeof GetVehicleDetailsResponse>;
export type PostDealParams = z.infer<typeof PostDealParams>;
export type PostDealResponse = z.infer<typeof PostDealResponse>;
