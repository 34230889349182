import { z } from 'zod';

export const HouseType = z.ZodEnum.create(['RENT', 'PARENTS_ADDRESS', 'DEED_PAID', 'DEED_PENDING_PAYMENT', 'OTHER']);
export type HouseType = z.infer<typeof HouseType>;

export const houseTypeOptions: Record<HouseType, string> = {
    RENT: 'Alquiler',
    PARENTS_ADDRESS: 'Domicilio padres',
    DEED_PAID: 'Escritura pagada totalmente',
    DEED_PENDING_PAYMENT: 'Escritura pdte de pago',
    OTHER: 'Otros',
};
