import { AnyObject } from '@/types';

export function objectToQueryString(query: AnyObject) {
    return Object.entries(query)
        ?.map(([key, value]) => {
            if (value === undefined) {
                return '';
            }

            if (Array.isArray(value)) {
                return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
            }

            return `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`;
        })
        .filter((part) => part !== '')
        .join('&');
}

export function queryStringToObject(queryString: string) {
    if (!queryString) return {};

    return queryString
        .replace('?', '')
        .split('&')
        .reduce((acc, curr) => {
            const [key, value] = curr.split('=');
            if (key && value) {
                acc[key] = decodeURIComponent(value);
            }
            return acc;
        }, {} as any);
}
