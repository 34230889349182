import { z } from 'zod';

export const ProfessionalStatus = z.ZodEnum.create(['NOT_WORK', 'EMPLOYEE', 'UNEMPLOYED', 'PENSIONER', 'FREELANCE']);
export type ProfessionalStatus = z.infer<typeof ProfessionalStatus>;

export const professionaStatusOptions: Record<ProfessionalStatus, string> = {
    NOT_WORK: 'No trabaja',
    EMPLOYEE: 'Empleado',
    UNEMPLOYED: 'Desempleado',
    PENSIONER: 'Pensionista',
    FREELANCE: 'Autónomo',
};
