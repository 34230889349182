import { z } from 'zod';
import { Author } from '@/types';

export const RefTypes = z.enum(['catalogue_vehicle']);

export const GenericNote = z.object({
    uuid: z.string(),
    refId: z.number(),
    refType: RefTypes,
    title: z.string(),
    content: z.string(),
    author: Author.nullable(),
    createdAt: z.string(),
    updatedAt: z.string(),
});

export const GetGenericNotesResponse = z
    .object({
        max: z.number(),
        offset: z.number(),
        items: z.array(GenericNote),
        totalItems: z.number(),
    })
    .required();

export const PostGenericNoteRequest = z.object({
    title: z.string(),
    content: z.string(),
});

export type GenericNote = z.infer<typeof GenericNote>;
export type GetGenericNotesResponse = z.infer<typeof GetGenericNotesResponse>;
export type PostGenericNoteRequest = z.infer<typeof PostGenericNoteRequest>;
